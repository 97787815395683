<template>
    <bread-page>
        <menu-page>
            <div class="menu-left" slot="menuList">
                <div class="menu-left-con">
                    <img src="../../assets/gongxu_icon_xuqiuxinxi@3x.png" alt="" class="menu-left-con_img" />
                    <div class="menu-left-con_title">用户中心</div>
                </div>
                <el-menu :default-active="active" router @select="handleSelect">
                    <el-menu-item index="/newsInfo">消息中心</el-menu-item>
                    <el-menu-item index="/publish/patent?caseType=GENERAL">我发布的</el-menu-item>
                    <el-menu-item index="/userEdit">个人资料</el-menu-item>
                    <el-menu-item index="/security">账号安全</el-menu-item>
                </el-menu>
            </div>

            <div class="main">
                <router-view />
            </div>
        </menu-page>
    </bread-page>
</template>

<script>
import { mapState } from 'vuex';
import BreadPage from '../../components/page/BreadPage.vue';
import MenuPage from '../../components/page/MenuPage.vue';
export default {
    components: { BreadPage, MenuPage },
    computed: {
        active() {
            return this.$route.meta.prePath || this.$route.path;
        },
        // isAuth() {
        //     return this.institutionInfo || this.personalInfo;
        // },
        ...mapState(['userInfo', 'institutionInfo', 'personalInfo', 'loginType']),
        type() {
            return this.userInfo.authorities.find(item => {
                return item.name == 'ROLE_INSTITUTION' || item.name == 'ROLE_PERSONAL';
            });
        }
    },
    created() {
        this.handleSelect();
    },
    methods: {
        handleSelect(key) {
            // this.$router.push('/userEdit');
            // if (key == '/userEdit') {
            //     if (this.type) {
            //         if (this.type.name == 'ROLE_INSTITUTION') {
            //             this.$nextTick(() => {
            //                 if (this.institutionInfo.status == 'PASS') {
            //                     this.$router.push('/userEdit');
            //                 } else if (this.institutionInfo.status == 'DENY') {
            //                     this.$router.push('/Authenticationsfail');
            //                 } else {
            //                     this.$router.push('/Authentications');
            //                 }
            //             });
            //         } else if (this.type.name == 'ROLE_PERSONAL') {
            //             this.$nextTick(() => {
            //                 if (this.personalInfo.status == 'PASS') {
            //                     this.$router.push('/userEdit');
            //                 } else if (this.personalInfo.status == 'DENY') {
            //                     this.$router.push('/Authenticationfail');
            //                 } else {
            //                     this.$router.push('/Authentication');
            //                 }
            //             });
            //         }
            //     } else {
            //         this.$router.push('/userEdit');
            //     }
            // }
        }
    }
};
</script>

<style lang="less" scoped>
.menu-left {
    min-height: 600px;
    background-color: #fff;
}
.menu-left-con {
    height: 60px;
    background: #01a041;
    display: flex;
    padding-left: 34px;
    align-items: center;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: bold;
    color: #ffffff;
}
.menu-left-con_title{
    font-size: 16px;
}
.menu-left-con_img {
    width: 24px;
    height: 24px;
    margin-right: 10px;
}
.main {
    .flex-col();
    background-color: #fff;
    flex-grow: 1;
    min-height: 1100px;
}
/deep/ .el-menu-item {
    // text-align: center;
    // padding-right: 60px;
    padding-left: 68px !important;
}
/deep/ .el-menu > .el-menu-item {
    &.is-active {
        background: linear-gradient(90deg, rgba(1, 160, 65, 0.1) 0%, rgba(255, 255, 255, 0.1) 100%);
        color: #01a041;
    }
}
&.is-active::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 60px;
    width: 1px;
    background-color: #01a041;
}
</style>
